<template>
    <div>
        <div class="btn-wrap">
                <div class="btn-list">
                    <a-button type="primary" @click="openAdd"><template #icon><plus-outlined /></template>添加类别</a-button>
                    <a-button type="danger" @click="delMore"><template #icon><delete-outlined /></template>删除</a-button>
                </div>
                <div>
                    <a-input-group compact>
                        <!-- <a-input allowClear style="margin-right:14px" v-model:value="tel"  placeholder="请输入手机号码"/> -->
                        <a-input allowClear v-model:value="search"  placeholder="请输入类别名称"/>
                        <a-button type="primary" @click="toSearch">搜索</a-button>
                    </a-input-group>
                </div>
            </div>

            <a-table row-key="id" :pagination="false" :columns="columns" :data-source="dataList.arr" :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }">
                <template #bodyCell="{ column,record}">
                    <!-- <template v-if="column.dataIndex === 'last_login_time'">
                        {{ changeFormdate(record.last_login_time) }}
                        
                    </template>
                    <template v-if="column.dataIndex === 'expiration_date'">
                        <a-button @click="openEdit(record.id,'date')" type="link" >{{record.expiration_date}}</a-button> 
                    </template> -->

                    <template v-if="column.dataIndex === 'status'">
                        <span v-if="record.status==1"> <a-tag color="success">正常</a-tag></span>
                        <span v-if="record.status==0"><a-tag color="error">禁用</a-tag></span>
                    </template>

                    <template v-if="column.dataIndex === 'operation'">
                        <a-button type="link" @click="openEdit(record.id)">编辑</a-button>
                        <a-button type="link" @click="del(record.id)">删除</a-button>
                    </template>
                </template>
            </a-table>

            <a-pagination
                v-model:pageSize="page.pageSize"
                show-size-changer
                :total="page.total"
                @change="pageChange"
                :show-total="total => `共 ${total} 条数据`"
            />

        <!-- 添加、编辑账户 -->
        <a-modal v-model:visible="visible" :title="title" @ok="handleOk" >
                <a-form
                    :model="formData.data"
                    :label-col="{ span: 4 }"
                    :wrapper-col="{ span: 20 }"
                    autocomplete="off"
                >
                    <a-form-item label="类别名称" name="title" >
                        <a-input placeholder="类别名称" v-model:value="formData.data.title" />
                    </a-form-item>

                    <a-form-item label="手机号" name="status" >
                        <a-radio-group v-model:value="formData.data.status">
                            <a-radio :value="1">正常</a-radio>
                            <a-radio :value="0">禁用</a-radio>
                        </a-radio-group>
                    </a-form-item>
            

                    <a-form-item label="排序" name="sort" >
                        <a-input  style="width:80%" placeholder="排序" v-model:value="formData.data.sort" />
                    </a-form-item>
                </a-form>
             
        </a-modal>
    </div>
</template>

<script>
import { reactive,ref,computed } from 'vue';
import {ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { getCurrentInstance ,createVNode} from 'vue'
import { message,Modal} from 'ant-design-vue';
import moment from 'moment';
import { formatDate } from "../../common/filter.js";
const columns = [
    {title: 'ID',dataIndex: 'id',width:90},
    {title: '类别名称',dataIndex: 'title'},
    {title: '状态',dataIndex: 'status'},
    {title: '添加时间',dataIndex: 'create_time'},
    {title: '操作',dataIndex: 'operation',width:180}
];
export default {
    setup(){
        const changeFormdate = computed(() => {
            return (time) => {
                let date = new Date(time);
                return formatDate(date);
            };
        });
        const { proxy } = getCurrentInstance()
        let dataList=reactive({arr:[]})
        
        let page=reactive({page:"",pageSize:"10",total:"20"})//分页

        // 搜索
        let search=ref()
        let tel=ref()
        let toSearch=function(){
            page.page="1"
            getTabledata()
        }

        let surplus_no_unit=ref("+")
        let surplus_no=ref()
        // 列表
         let getTabledata=function(){
            proxy.$http.post('/admin/createtypelist',{page:page.page,pagesize:page.pageSize,username:search.value,telephone:tel.value,},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    dataList.arr=res.data.data.data
                    page.total=res.data.data.total
                }else{
                    message.error(res.data.msg);
                }
            });
        }
        getTabledata()

        // 添加、编辑

        let dateFormat= ref('YYYY-MM-DD')
        let editdate=ref()
        // let dateChage=function(date,dateString){
        //     editdate.value=moment(dateString+' 23:59:59').unix()
            
        //     console.log(editdate.value)
        // }

        let formData=reactive({data:{title:"",sort:"",status:1}})
        let title=ref("")
        let visible=ref(false)
        let openAdd=function(){
            formData.data.title=""
            formData.data.sort=""
            formData.data.status=1
            title.value="添加类别"
            visible.value=true
        }
        let isedit=ref(true)
        let isnum=ref(false)
        let isdate=ref(false)
        let openEdit=function(id){
            proxy.$http.post('/admin/createtypepage',{id:id},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    visible.value=true
                    title.value="编辑类别"
                    formData.data=res.data.data.data
                }else{
                    message.error(res.data.msg);
                }
            });
        }

        let handleOk=function(){
            if(title.value=="添加类别"){
            
                proxy.$http.post('/admin/addcreatetype',{title:formData.data.title,sort:formData.data.sort,status:formData.data.status},{
                    headers:{
                        'token':sessionStorage.getItem("token")
                    }
                }).then((res) => {
                    if(res.data.status==200){
                        visible.value=false
                        message.success(res.data.msg)
                        getTabledata()
                    }else{
                        message.error(res.data.msg);
                    }
                });
                
                
            }else if(title.value=="编辑类别"){
                proxy.$http.post('/admin/updatecreatetype',{id:formData.data.id,title:formData.data.title,sort:formData.data.sort,status:formData.data.status},{
                    headers:{
                        'token':sessionStorage.getItem("token")
                    }
                }).then((res) => {
                    if(res.data.status==200){
                        visible.value=false
                        message.success(res.data.msg)
                        getTabledata()
                    }else{
                        message.error(res.data.msg);
                    }
                });
            }
            
        }
        // 多选
        let selectedRowKeys=ref()
        let onSelectChange=function(selected) {
            selectedRowKeys.value = selected;
        }

         // 删除
        let delMore=function(){
            var id=selectedRowKeys.value.join(",")
            del(id)
        }
        let del=function(id){
            Modal.confirm({
                title: '你确定要删除吗？',
                icon: createVNode(ExclamationCircleOutlined),
            onOk() {
                proxy.$http.post('/admin/delcreatetype',{ids:id},{
                    headers:{
                        'token':sessionStorage.getItem("token")
                    }
                }).then((res) => {
                    if(res.data.status==200){
                        message.success(res.data.msg)
                        getTabledata()
                    }else{
                        message.error(res.data.msg);
                    }
                });
            },
            onCancel() {
             console.log('Cancel');
            }
            })
            
        }



        // 分页
        let pageChange=function(current, size){
            page.pageSize=size
            page.page=current
            getTabledata()
        }
        return{
            dateFormat,surplus_no_unit,surplus_no,
            isedit,isnum,isdate,
            changeFormdate,
            toSearch,search,tel,
            visible,formData,openAdd,title,handleOk,openEdit,
            onSelectChange,delMore,del,selectedRowKeys,
            columns,dataList,pageChange,page
        }
    }
}
</script>