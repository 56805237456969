<template>
    <div>
        <div class="btn-wrap">
                <div class="btn-list">
                    <a-button type="primary" @click="openAdd"><template #icon><plus-outlined /></template>添加会员</a-button>
                    <a-button type="danger" @click="delMore"><template #icon><delete-outlined /></template>删除</a-button>
                </div>
                <div>
                    <a-input-group compact>
                        <a-input allowClear style="margin-right:14px" v-model:value="tel"  placeholder="请输入手机号码"/>
                        <a-input allowClear v-model:value="search"  placeholder="请输入会员姓名"/>
                        <a-button type="primary" @click="toSearch">搜索</a-button>
                    </a-input-group>
                </div>
            </div>

            <a-table row-key="id" :pagination="false" :columns="columns" :data-source="dataList.arr" :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }">
                <template #bodyCell="{ column,record}">
                    <template v-if="column.dataIndex === 'last_login_time'">
                        {{ changeFormdate(record.last_login_time) }}
                        
                    </template>
                    <template v-if="column.dataIndex === 'expiration_date'">
                        <a-button @click="openEdit(record.id,'date')" type="link" >{{record.expiration_date}}</a-button> 
                    </template>

                    <template v-if="column.dataIndex === 'usableno'">
                        <a-button @click="openEdit(record.id,'num')" type="link" >{{record.usableno}}</a-button> 
                    </template>

                    <template v-if="column.dataIndex === 'operation'">
                        <a-button type="link" @click="openEdit(record.id,'edit')">编辑</a-button>
                        <a-button type="link" @click="del(record.id)">删除</a-button>
                    </template>
                </template>
            </a-table>

            <a-pagination
                v-model:pageSize="page.pageSize"
                show-size-changer
                :total="page.total"
                @change="pageChange"
                :show-total="total => `共 ${total} 条数据`"
            />

        <!-- 添加、编辑账户 -->
        <a-modal v-model:visible="visible" :title="title" @ok="handleOk" >
                <a-form
                    :model="formData.data"
                    :label-col="{ span: 4 }"
                    :wrapper-col="{ span: 20 }"
                    autocomplete="off"
                >
                    <a-form-item v-if="isedit" label="姓名" name="username" >
                        <a-input placeholder="姓名" v-model:value="formData.data.username" />
                    </a-form-item>

                    <a-form-item v-if="isedit" label="手机号" name="telephone" >
                        <a-input placeholder="手机号" type="tel" v-model:value="formData.data.telephone" />
                    </a-form-item>
                
                    <a-form-item v-if="isedit" label="密码" name="password" >
                        <a-input-password placeholder="密码" v-model:value="formData.data.password" />
                    </a-form-item>
                    <a-form-item v-if="isedit" label="确认密码" name="repassword" >
                        <a-input-password placeholder="确认密码" v-model:value="formData.data.repassword" />
                    </a-form-item>

                    <a-form-item v-if="isnum" label="充值条数" name="usableno" >
                        <a-input-group compact>
                            <a-select v-model:value="surplus_no_unit">
                                <a-select-option value="+">增加</a-select-option>
                                <a-select-option value="-">减少</a-select-option>
                            </a-select>
                            <a-input v-model:value="surplus_no" placeholder="充值条数" style="width: 50%" />
                        </a-input-group>
                        <!-- <a-input placeholder="充值条数" v-model:value="formData.data.usableno" /> -->
                    </a-form-item>

                    <a-form-item v-if="isdate" label="到期时间" name="expiration_date" >
                        <a-date-picker style="width:80%"  @change="dateChage" placeholder="到期时间" v-model:value="formData.data.expiration_date" />
                    </a-form-item>
                </a-form>
             
        </a-modal>
    </div>
</template>

<script>
import { reactive,ref,computed } from 'vue';
import {ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { getCurrentInstance ,createVNode} from 'vue'
import { message,Modal} from 'ant-design-vue';
import moment from 'moment';
import { formatDate } from "../../common/filter.js";
const columns = [
    {title: 'ID',dataIndex: 'id',width:90},
    {title: '姓名',dataIndex: 'username'},
    {title: '手机号',dataIndex: 'telephone'},
    // {title: '最后登录IP',dataIndex: 'last_login_ip'},
    {title: '最后登录时间',dataIndex: 'last_login_time'},
    {title: '使用条数',dataIndex: 'usedno'},
    {title: '总条数',dataIndex: 'usableno'},
    {title: '使用金额',dataIndex: 'sum_order_amount'},
    {title: '到期时间',dataIndex: 'expiration_date'},
    {title: '注册时间',dataIndex: 'create_time'},
    {title: '操作',dataIndex: 'operation',width:180}
];
export default {
    setup(){
        const changeFormdate = computed(() => {
            return (time) => {
                let date = new Date(time);
                return formatDate(date);
            };
        });
        const { proxy } = getCurrentInstance()
        let dataList=reactive({arr:[]})
        
        let page=reactive({page:"",pageSize:"10",total:"20"})//分页

        // 搜索
        let search=ref()
        let tel=ref()
        let toSearch=function(){
            page.page="1"
            getTabledata()
        }

        let surplus_no_unit=ref("+")
        let surplus_no=ref()
        // 列表
         let getTabledata=function(){
            proxy.$http.post('/admin/userlist',{page:page.page,pagesize:page.pageSize,username:search.value,telephone:tel.value,},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    dataList.arr=res.data.data.data
                    page.total=res.data.data.total
                }else{
                    message.error(res.data.msg);
                }
            });
        }
        getTabledata()

        // 添加、编辑

        let dateFormat= ref('YYYY-MM-DD')
        let editdate=ref()
        let dateChage=function(date,dateString){
            editdate.value=moment(dateString+' 23:59:59').unix()
            
            console.log(editdate.value)
        }

        let formData=reactive({data:{username:"",telephone:"",password:"",repassword:"",expiration_date:""}})
        let title=ref("")
        let visible=ref(false)
        let openAdd=function(){
            formData.data.username=""
            formData.data.telephone=""
            formData.data.repassword=""
            formData.data.password=""
            title.value="添加会员"
            visible.value=true
        }
        let isedit=ref(true)
        let isnum=ref(false)
        let isdate=ref(false)
        let openEdit=function(id,isflag){
            surplus_no.value=""
            surplus_no_unit.value="+"
            if(isflag=="edit"){
                isedit.value=true
                isnum.value=false
                isdate.value=false
            }
            if(isflag=="date"){
                isedit.value=false
                isnum.value=false
                isdate.value=true
            }
            if(isflag=="num"){
                isedit.value=false
                isnum.value=true
                isdate.value=false
            }
            proxy.$http.post('/admin/userpage',{id:id},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    visible.value=true
                    title.value="编辑会员"
                    formData.data=res.data.data.data
                    
                    formData.data.password=""
                    formData.data.repassword=""
                }else{
                    message.error(res.data.msg);
                }
            });
        }

        let handleOk=function(){

            if(formData.data.password!=formData.data.repassword){
                message.error("两次密码不一致")
                return false
            }
            if(isedit.value==true){
                if(title.value=="添加会员"){
               
                    proxy.$http.post('/admin/adduser',{username:formData.data.username,telephone:formData.data.telephone,password:formData.data.password,
                    admin_user_id:sessionStorage.getItem("userid")},{
                        headers:{
                            'token':sessionStorage.getItem("token")
                        }
                    }).then((res) => {
                        if(res.data.status==200){
                            visible.value=false
                            message.success(res.data.msg)
                            getTabledata()
                        }else{
                            message.error(res.data.msg);
                        }
                    });
                    
                    
                }else if(title.value=="编辑会员"){
                    proxy.$http.post('/admin/updateuser',{id:formData.data.id,username:formData.data.username,telephone:formData.data.telephone,password:formData.data.password,
                    admin_user_id:sessionStorage.getItem("userid")},{
                        headers:{
                            'token':sessionStorage.getItem("token")
                        }
                    }).then((res) => {
                        if(res.data.status==200){
                            visible.value=false
                            message.success(res.data.msg)
                            getTabledata()
                        }else{
                            message.error(res.data.msg);
                        }
                    });
                }
            }else{
                
                if(surplus_no_unit.value=='+'){
                    surplus_no.value=surplus_no.value
                }else if(surplus_no_unit.value=='-'){
                    surplus_no.value="-"+surplus_no.value
                }
                // console.log(surplus_no.value)
                proxy.$http.post('/admin/updateordertouser',{user_id:formData.data.id,end_time:editdate.value,surplus_no:surplus_no.value},{
                    headers:{
                        'token':sessionStorage.getItem("token")
                    }
                }).then((res) => {
                    if(res.data.status==200){
                        visible.value=false
                        message.success(res.data.msg)
                        getTabledata()
                    }else{
                        message.error(res.data.msg);
                    }
                });
            }
            
        }
        // 多选
        let selectedRowKeys=ref()
        let onSelectChange=function(selected) {
            selectedRowKeys.value = selected;
        }

         // 删除
        let delMore=function(){
            var id=selectedRowKeys.value.join(",")
            del(id)
        }
        let del=function(id){
            Modal.confirm({
                title: '你确定要删除吗？',
                icon: createVNode(ExclamationCircleOutlined),
            onOk() {
                proxy.$http.post('/admin/deluser',{ids:id},{
                    headers:{
                        'token':sessionStorage.getItem("token")
                    }
                }).then((res) => {
                    if(res.data.status==200){
                        message.success(res.data.msg)
                        getTabledata()
                    }else{
                        message.error(res.data.msg);
                    }
                });
            },
            onCancel() {
             console.log('Cancel');
            }
            })
            
        }



        // 分页
        let pageChange=function(current, size){
            page.pageSize=size
            page.page=current
            getTabledata()
        }
        return{
            dateFormat,dateChage,surplus_no_unit,surplus_no,
            isedit,isnum,isdate,
            changeFormdate,
            toSearch,search,tel,
            visible,formData,openAdd,title,handleOk,openEdit,
            onSelectChange,delMore,del,selectedRowKeys,
            columns,dataList,pageChange,page
        }
    }
}
</script>