
<template>
    <div>
        <div class="table-wrap">
            <div class="btn-wrap">
                <div class="btn-list">
                    <a-button type="primary" @click="openAdd"><template #icon><UploadOutlined /></template>添加权限</a-button>
                    <a-button type="danger" @click="delMore"><template #icon><DeleteOutlined /></template>删除</a-button>
                </div>
                <div>
                    <a-input-group compact>
                        <a-input v-model:value="search" placeholder="请输入权限名称"/>
                        <a-button type="primary" @click="toSearch">搜索</a-button>
                    </a-input-group>
                </div>
                
            </div>
            <a-table row-key="id" :pagination="false" :columns="columns" :data-source="dataList.arr"
                :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
            >
                <template #bodyCell="{ column,record}">
                    <template v-if="column.dataIndex === 'operation'">
                        <a-button type="link" @click="openEdit(record.id)">编辑</a-button>
                        <a-button type="link" @click="del(record.id)">删除</a-button>
                    </template>
                </template>
            </a-table>

             <a-pagination
                v-model:pageSize="page.pageSize"
                show-size-changer
                :total="page.total"
                @change="pageChange"
                :show-total="total => `共 ${total} 条数据`"
            />
        </div>

        <!-- 添加、编辑权限 -->
        <a-modal v-model:visible="visible" :title="title" @ok="handleOk" >
            <div style="height:380px;overflow-y:auto">
                <a-form
                    :model="formData.data"
                    :label-col="{ span: 4 }"
                    :wrapper-col="{ span: 16 }"
                    autocomplete="off"
                >
                    <a-form-item label="权限名称" name="title" >
                        <a-input placeholder="权限名称" v-model:value="formData.data.title" />
                    </a-form-item>
                    <a-form-item label="权限设置" name="title" >
                        <a-tree ref="treeRef" v-model:checkedKeys="formData.data.node_ids" checkable @check="nodeCheck" :tree-data="treeData.arr" :field-names="fieldNames"> 

                        </a-tree>
                    </a-form-item>
                    <a-form-item label="排序" name="sort" >
                        <a-input placeholder="排序" v-model:value="formData.data.sort" />
                    </a-form-item>
                </a-form>
            </div>
            
        </a-modal>
    </div>
</template>

<script>
import { reactive,ref } from 'vue';
import {UploadOutlined,ApartmentOutlined,GatewayOutlined,DeleteOutlined,ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { getCurrentInstance ,createVNode} from 'vue'
import { message,Modal} from 'ant-design-vue';
const columns = [
  {title: 'ID',dataIndex: 'id'},
  {title: '权限名称',dataIndex: 'title'},
  {title: '排序',dataIndex: 'sort'},
  {title: '添加时间',dataIndex: 'create_time'},
  {title: '操作',dataIndex: 'operation',width:180},
];

export default {
    components:{
        UploadOutlined,ApartmentOutlined,GatewayOutlined,DeleteOutlined
    },
    setup(){
        let dataList=reactive({arr:[]})
        let selectedRowKeys=ref()
        let page=reactive({page:"1",pageSize:"10",total:""})//分页
        const { proxy } = getCurrentInstance()
          // 搜索
        let search=ref()
        let toSearch=function(){
            page.page="1"
            getTabledata()
        }

        // 列表
        let getTabledata=function(){
             proxy.$http.post('/admin/rolelist',{page:page.page,pagesize:page.pageSize,search:search.value},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    dataList.arr=res.data.data.data
                    page.total=res.data.data.total
                }else{
                    message.error(res.data.msg);
                }
            });
        }
        getTabledata()

        // 权限
        let fieldNames={key:"id"}
        let fatherNode=reactive({arr:[]})
        let treeData=reactive({arr:[]})
        let getNode=function(){
             proxy.$http.post('/admin/nodelist',{},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    var data=res.data.data
                    for(var i in data){
                        fatherNode.arr.push(data[i].id)
                        for(var j in data[i].children){
                            if(data[i].children[j].children){
                                fatherNode.arr.push(data[i].children[j].id)
                            }
                        }
                    }
                    treeData.arr=res.data.data
                }else{
                    message.error(res.data.msg);
                }
            });
        }
        getNode()

        let nodeids=ref([])
        let nodeCheck=function(checkedKeys,e){
            
            nodeids.value=checkedKeys
            nodeids.value.push(e.halfCheckedKeys)
        }
        // 添加、编辑
        let formData=reactive({data:{title:"",pid:"0",node_ids:"",sort:""}})
        let visible=ref(false)
        let title=ref("添加权限")
        let openAdd=function(){
            formData.data.title=""
            formData.data.pid="0"
            formData.data.node_ids=""
            formData.data.sort=""
            visible.value=true
            title.value="添加权限"
        }

        const treeRef = ref(null)
        let openEdit=function(id){
             proxy.$http.post('/admin/rolepage',{id:id},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    visible.value=true
                    title.value="编辑权限"
                    formData.data=res.data.data.data
                    var arr1=res.data.data.data.node_ids.split(",")
                    var arr2=[]

                    console.log(fatherNode.arr)
                    for(var i in arr1){
                         console.log(fatherNode.arr.indexOf(parseInt(arr1[i])))
                        if(fatherNode.arr.indexOf(parseInt(arr1[i]))==-1){
                            arr2.push(parseInt(arr1[i])) 
                        }
                    }
                    formData.data.node_ids=arr2

                }else{
                    message.error(res.data.msg);
                }
            });
        }
        let handleOk=function(){
            if(nodeids.value.length>0){
                formData.data.node_ids= nodeids.value.join(",")
            }
            console.log(title.value)
            if(title.value=="添加权限"){
                proxy.$http.post('/admin/addrole',{title:formData.data.title,pid:formData.data.pid,node_ids:formData.data.node_ids,sort:formData.data.sort},{
                    headers:{
                        'token':sessionStorage.getItem("token")
                    }
                }).then((res) => {
                    if(res.data.status==200){
                        visible.value=false
                        message.success(res.data.msg);
                        getTabledata()
                    }else{
                        message.error(res.data.msg);
                    }
                });
            }else if(title.value=="编辑权限"){
                proxy.$http.post('/admin/updaterole',{id:formData.data.id,title:formData.data.title,pid:formData.data.pid,node_ids:formData.data.node_ids,sort:formData.data.sort},{
                    headers:{
                        'token':sessionStorage.getItem("token")
                    }
                }).then((res) => {
                    if(res.data.status==200){
                        visible.value=false
                        message.success(res.data.msg);
                        getTabledata()
                    }else{
                        message.error(res.data.msg);
                    }
                });
            }
        }
        // 多选
        let onSelectChange=function(selected) {
            selectedRowKeys.value = selected;
        }

         // 删除
        let delMore=function(){
            var id=selectedRowKeys.value.join(",")
            del(id)
        }
        let del=function(id){
            Modal.confirm({
                title: '你确定要删除吗？',
                icon: createVNode(ExclamationCircleOutlined),
            onOk() {
                proxy.$http.post('/admin/delrole',{ids:id},{
                    headers:{
                        'token':sessionStorage.getItem("token")
                    }
                }).then((res) => {
                    if(res.data.status==200){
                        message.success(res.data.msg)
                        getTabledata()
                    }else{
                        message.error(res.data.msg);
                    }
                });
            },
            onCancel() {
             console.log('Cancel');
            }
            })
        }

        
      
        // 分页
        let pageChange=function(current, size){
            page.pageSize=size
            page.page=current
            getTabledata()
        }
        return {
            treeData,fieldNames,nodeCheck,treeRef,
            search,toSearch,
            dataList,columns,
            page,pageChange,
            onSelectChange,selectedRowKeys,delMore,del,
            formData,visible,title,handleOk,openAdd,openEdit
        };
    }
}
</script>