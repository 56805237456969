<template>
    <div>
        <div class="btn-wrap">
                <div class="btn-list">
                    <a-button type="primary" @click="openAdd"><template #icon><plus-outlined /></template>添加模型</a-button>
                    <a-button type="danger" @click="delMore"><template #icon><delete-outlined /></template>删除</a-button>
                </div>
                <div>
                    <a-input-group compact>
                        <!-- <a-input allowClear style="margin-right:14px" v-model:value="tel"  placeholder="请输入手机号码"/> -->
                        <a-input allowClear v-model:value="search"  placeholder="请输入模型名称"/>
                        <a-button type="primary" @click="toSearch">搜索</a-button>
                    </a-input-group>
                </div>
            </div>

            <a-table row-key="id" :pagination="false" :columns="columns" :data-source="dataList.arr" :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }">
                <template #bodyCell="{ column,record}">
                    <!-- <template v-if="column.dataIndex === 'last_login_time'">
                        {{ changeFormdate(record.last_login_time) }}
                        
                    </template>
                    <template v-if="column.dataIndex === 'expiration_date'">
                        <a-button @click="openEdit(record.id,'date')" type="link" >{{record.expiration_date}}</a-button> 
                    </template> -->

                    <template v-if="column.dataIndex === 'status'">
                        <a-switch @change="checked => changeStatus(checked, record.id)" :checkedValue="1" :unCheckedValue="0" checked-children="正常" un-checked-children="禁用" v-model:checked="record.status" />
                    </template>

                    <template v-if="column.dataIndex === 'operation'">
                        <a-button type="link" @click="openEdit(record.id)">编辑</a-button>
                        <a-button type="link" @click="del(record.id)">删除</a-button>
                    </template>
                </template>
            </a-table>

            <a-pagination
                v-model:pageSize="page.pageSize"
                show-size-changer
                :total="page.total"
                @change="pageChange"
                :show-total="total => `共 ${total} 条数据`"
            />

        <!-- 添加、编辑模型-->
        <a-modal v-model:visible="visible" class="scroll-model"  :title="title" @ok="handleOk" >
                <a-form
              
                    :model="formData.data"
                    :label-col="{ span: 4 }"
                    :wrapper-col="{ span: 20 }"
                    autocomplete="off"
                >
                    <a-form-item label="模型名称" name="title" >
                        <a-input placeholder="模型名称" v-model:value="formData.data.title" />
                    </a-form-item>
                    <a-form-item label="所属类别" name="title" >
                        <a-select v-model:value="formData.data.chat_create_type_id" placeholder="模型" >
                            <a-select-option v-for="item in typelist.arr" :key="item.id" :value="item.id">{{item.title}}</a-select-option>
                        </a-select>
                    </a-form-item>
                    <a-form-item label="描述" name="description" >
                        <a-textarea :rows="4" placeholder="描述" v-model:value="formData.data.description" />
                    </a-form-item>
                    <a-form-item label="内容" name="content" >
                        <a-textarea :rows="4" placeholder=" 例：用[CREATEKEYWORDES]写一篇关于[CREATELANGGE]的工作周报、日报汇报，用于工作汇报的文案" v-model:value="formData.data.content" />
                        <p>变量说明：</p>
                        <p> 用户输入内容： [CREATEKEYWORDES] </p>
                        <p> 输出语言： [CREATELANGGE]</p>
                    </a-form-item>

                    <a-form-item label="提示文字" name="prompt" >
                        <a-textarea :rows="4"  placeholder="提示文字" v-model:value="formData.data.prompt" />
                    </a-form-item>

                    <a-form-item label="欢迎语" name="welcome" >
                        <a-textarea :rows="4"  placeholder="欢迎语，可留空，默认使用提示文字" v-model:value="formData.data.welcome" />
                    </a-form-item>

                    <a-form-item  class="form-flex-wrap" label="" name="status" >
                        <div class="form-flex">
                            <a-form-item label="虚拟点击" name="click_virtually" >
                                <a-input placeholder="虚拟点击" v-model:value="formData.data.click_virtually" />
                            </a-form-item> 
                            <a-form-item label="虚拟使用" name="used__virtually" >
                                <a-input placeholder="虚拟使用" v-model:value="formData.data.used__virtually" />
                            </a-form-item> 
                            <a-form-item label="虚拟收藏" name="collect_virtually" >
                                <a-input placeholder="虚拟收藏" v-model:value="formData.data.collect_virtually" />
                            </a-form-item> 
                        </div>
                    </a-form-item>
                    <a-form-item label="状态" name="status" >
                         <a-switch :checkedValue="1" :unCheckedValue="0" checked-children="正常" un-checked-children="禁用" v-model:checked="formData.data.status" />
                    </a-form-item>

                    <a-form-item label="排序" name="sort" >
                        <a-input  style="width:80%" placeholder="排序" v-model:value="formData.data.sort" />
                    </a-form-item>
                </a-form>
             
        </a-modal>
    </div>
</template>

<script>
import { reactive,ref,computed } from 'vue';
import {ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { getCurrentInstance ,createVNode} from 'vue'
import { message,Modal} from 'ant-design-vue';
import moment from 'moment';
import { formatDate } from "../../common/filter.js";
const columns = [
    {title: 'ID',dataIndex: 'id',width:90},
    {title: '所属类别',dataIndex: 'title_chat_create_type'},
    {title: '模型名称',dataIndex: 'title'},
    {title: '描述',dataIndex: 'description',width:420},
    {title: '状态',dataIndex: 'status'},
    {title: '添加时间',dataIndex: 'create_time',width:180},
    {title: '操作',dataIndex: 'operation',width:180}
];
export default {
    setup(){
        const changeFormdate = computed(() => {
            return (time) => {
                let date = new Date(time);
                return formatDate(date);
            };
        });
        const { proxy } = getCurrentInstance()
        let dataList=reactive({arr:[]})
        
        let page=reactive({page:"",pageSize:"10",total:"20"})//分页

        // 类别列表
        let typelist =reactive({arr:[]})
        let getTypelist=function(){
            proxy.$http.post('/admin/createtypelist',{page:1,pagesize:300},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    typelist.arr=res.data.data.data
                }else{
                    message.error(res.data.msg);
                }
            });
        }
        getTypelist()

        // 搜索
        let search=ref()
        let tel=ref()
        let toSearch=function(){
            page.page="1"
            getTabledata()
        }

        let surplus_no_unit=ref("+")
        let surplus_no=ref()
        // 列表
         let getTabledata=function(){
            proxy.$http.post('/admin/createmodellist',{page:page.page,pagesize:page.pageSize,username:search.value,telephone:tel.value,},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    dataList.arr=res.data.data.data
                    page.total=res.data.data.total
                }else{
                    message.error(res.data.msg);
                }
            });
        }
        getTabledata()

        let changeStatus=function(checked,id){

            proxy.$http.post('/admin/updatecreatemodel',{id:id,pid:formData.data.pid,status:checked},{
                    headers:{
                        'token':sessionStorage.getItem("token")
                    }
                }).then((res) => {
                    if(res.data.status==200){
                        visible.value=false
                        message.success(res.data.msg)
                        getTabledata()
                    }else{
                        message.error(res.data.msg);
                    }
                });
        }

        // 添加、编辑

        let dateFormat= ref('YYYY-MM-DD')
        let editdate=ref()
        // let dateChage=function(date,dateString){
        //     editdate.value=moment(dateString+' 23:59:59').unix()
            
        //     console.log(editdate.value)
        // }

        let formData=reactive({data:{title:"",chat_create_type_id:"",description:"",content:"",prompt:"",welcome:"",click_virtually:"",used__virtually:"",collect_virtually:"",sort:"",status:1}})
        let title=ref("")
        let visible=ref(false)
        let openAdd=function(){
            formData.data.title=""
            formData.data.chat_create_type_id=""
            formData.data.description=""
            formData.data.content=""
            formData.data.prompt=""
            formData.data.welcome=""
            formData.data.click_virtually=""
            formData.data.used__virtually=""
            formData.data.collect_virtually=""
            formData.data.sort=""
            formData.data.status=1
            title.value="添加模型"
            visible.value=true
        }
        let isedit=ref(true)
        let isnum=ref(false)
        let isdate=ref(false)
        let openEdit=function(id){
            proxy.$http.post('/admin/createmodelpage',{id:id},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    visible.value=true
                    title.value="编辑模型"
                    formData.data=res.data.data.data
                }else{
                    message.error(res.data.msg);
                }
            });
        }

        let handleOk=function(){
            if(title.value=="添加模型"){
            
                proxy.$http.post('/admin/addcreatemodel',{title:formData.data.title,chat_create_type_id:formData.data.chat_create_type_id,description:formData.data.description,
                content:formData.data.content,prompt:formData.data.prompt,prompt:formData.data.prompt,welcome:formData.data.welcome,
                click_virtually:formData.data.click_virtually,used__virtually:formData.data.used__virtually,collect_virtually:formData.data.collect_virtually,
                sort:formData.data.sort,status:formData.data.status},{
                    headers:{
                        'token':sessionStorage.getItem("token")
                    }
                }).then((res) => {
                    if(res.data.status==200){
                        visible.value=false
                        message.success(res.data.msg)
                        getTabledata()
                    }else{
                        message.error(res.data.msg);
                    }
                });
                
                
            }else if(title.value=="编辑模型"){
                proxy.$http.post('/admin/updatecreatemodel',{id:formData.data.id,title:formData.data.title,chat_create_type_id:formData.data.chat_create_type_id,description:formData.data.description,
                content:formData.data.content,prompt:formData.data.prompt,prompt:formData.data.prompt,welcome:formData.data.welcome,
                click_virtually:formData.data.click_virtually,used__virtually:formData.data.used__virtually,collect_virtually:formData.data.collect_virtually,
                sort:formData.data.sort,status:formData.data.status},{
                    headers:{
                        'token':sessionStorage.getItem("token")
                    }
                }).then((res) => {
                    if(res.data.status==200){
                        visible.value=false
                        message.success(res.data.msg)
                        getTabledata()
                    }else{
                        message.error(res.data.msg);
                    }
                });
            }
            
        }
        // 多选
        let selectedRowKeys=ref()
        let onSelectChange=function(selected) {
            selectedRowKeys.value = selected;
        }

         // 删除
        let delMore=function(){
            var id=selectedRowKeys.value.join(",")
            del(id)
        }
        let del=function(id){
            Modal.confirm({
                title: '你确定要删除吗？',
                icon: createVNode(ExclamationCircleOutlined),
            onOk() {
                proxy.$http.post('/admin/delcreatemodel',{ids:id},{
                    headers:{
                        'token':sessionStorage.getItem("token")
                    }
                }).then((res) => {
                    if(res.data.status==200){
                        message.success(res.data.msg)
                        getTabledata()
                    }else{
                        message.error(res.data.msg);
                    }
                });
            },
            onCancel() {
             console.log('Cancel');
            }
            })
            
        }



        // 分页
        let pageChange=function(current, size){
            page.pageSize=size
            page.page=current
            getTabledata()
        }
        return{
            typelist,
            dateFormat,surplus_no_unit,surplus_no,
            isedit,isnum,isdate,
            changeFormdate,
            toSearch,search,tel,
            visible,formData,openAdd,title,handleOk,openEdit,changeStatus,
            onSelectChange,delMore,del,selectedRowKeys,
            columns,dataList,pageChange,page
        }
    }
}
</script>