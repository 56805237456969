import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import login from '../views/login.vue'
import nodelist from '../views/authority/nodelist'
import rolelist from '../views/authority/rolelist'
import adminlist from '../views/authority/adminlist'

import loglist from '../views/logadmin/loglist'
import system from '../views/system/system'
import sharelist from '../views/system/sharelist'

import member from '../views/account/member'
import person from '../views/account/person'

import packlist from '../views/payadmin/packlist'
import paylist from '../views/payadmin/paylist'
import zfblist from '../views/payadmin/zfblist'

import orderlist from '../views/order/orderlist'

import chatlist from '../views/chatadmin/chatlist'

import createcate from '../views/create/createcate'
import createlist from '../views/create/createlist'
import createmodel from '../views/create/createmodel'

import index from '../views/index/index'
const routes = [
  { path: '/', name: 'home', component: HomeView, redirect: {path: '/index' } ,
  children:[
    {path:'index',name:'index',component:index,meta:{title:"首页"}},
    {path:"/nodelist",name:"nodelist", component: nodelist ,meta:{title:"节点列表"}},
    {path:"/chatlist",name:"chatlist", component: chatlist ,meta:{title:"聊天列表"}},
    {path:"/adminlist",name:"adminlist", component: adminlist ,meta:{title:"管理员列表"}},
    {path:"/sharelist",name:"sharelist", component: sharelist ,meta:{title:"推广配置"}},
    {path:"/rolelist",name:"rolelist", component: rolelist ,meta:{title:"角色列表"}},
    {path:"/orderlist",name:"orderlist", component: orderlist ,meta:{title:"订单列表"}},

    {path:"/createcate",name:"createcate", component: createcate ,meta:{title:"创作类别"}},
    {path:"/createlist",name:"createlist", component: createlist ,meta:{title:"创作列表"}},
    {path:"/createmodel",name:"createmodel", component: createmodel ,meta:{title:"创作模型"}},
    
    {path:"/loglist",name:"loglist", component: loglist ,meta:{title:"日志列表"}},
    {path:"/system",name:"system", component: system ,meta:{title:"系统配置"}},

    {path:"/member",name:"member", component: member ,meta:{title:"会员管理"}},
    {path:"/person",name:"person", component: person ,meta:{title:"个人信息"}},

    {path:"/packlist",name:"packlist", component: packlist ,meta:{title:"套餐列表"}},
    {path:"/paylist",name:"paylist", component: paylist ,meta:{title:"微信账户"}},
    {path:"/zfblist",name:"zfblist", component: zfblist ,meta:{title:"支付宝账户"}},
    
  ]},
  {path: '/login',name: 'login',component: login,meta:{title:"登录"}}
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from , next) => {
  const title = (to.meta.title ? to.meta.title : '') + '-aichat管理平台'
  document.title = title
  // 判断是否含有登录标识
  const isLogin = sessionStorage.getItem('token')
  console.log("isLogin="+isLogin)
  // 想要跳转到 person页面 但是没有标识 就next 去登录
    if(!isLogin){
      if(to.name=="login"){
        next()
      }else{
        router.push('login')
      }
    }else{
      next()
    }
})

export default router
