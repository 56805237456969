<template>
    <div class="main-index" >
        <div class="main-top-search">
            <h1 class="main-index-title">AIChat后台管理平台</h1>
            <div class="date-search">
                <a-range-picker  @change="dateChage" :format="dateFormat" />
                <a-button type="primary" @click="toSearch">搜索</a-button>
            </div>
        </div>
            
            <a-row :gutter="32" style="height:92%">
                <a-col :span="24"  class="index-top-content">
                    <a-row :gutter="32">
                        <a-col :span="5" class="left-top-wrap">
                            <h2 class="left-top">
                                <p class="top-text"><contacts-filled :style="{ color: '#3191ff' ,fontSize:'26px'}"/>今日新增用户</p>
                                <span style="color:#3191ff" class="num">{{totalData.data.user_no_new_increase}}</span>
                                <div class="bottom-text">
                                    <span>总计用户数</span>
                                    <span>{{totalData.data.user_no}}</span>
                                </div>
                            </h2>
                            
                        </a-col>
                        <a-col :span="5" class="left-top-wrap">
                             <h2 class="left-top">
                                <p class="top-text"><contacts-filled :style="{ color: '#3191ff' ,fontSize:'26px'}"/>今日提问数</p>
                                <span style="color:#3191ff" class="num">{{totalData.data.chat_log_no_new_increase}}</span>
                                <div class="bottom-text">
                                    <span>总计提问数</span>
                                    <span>{{totalData.data.chat_log_no}}</span>
                                </div>
                            </h2>
                        </a-col>
                        <a-col :span="5" class="left-top-wrap">
                             <h2 class="left-top">
                                <p class="top-text"><contacts-filled :style="{ color: '#3191ff' ,fontSize:'26px'}"/>今日创作数</p>
                                <span style="color:#3191ff" class="num">{{totalData.data.chat_log_no_createt_new_increase}}</span>
                                <div class="bottom-text">
                                    <span>总计创作数</span>
                                    <span>{{totalData.data.chat_log_no_createt}}</span>
                                </div>
                            </h2>
                        </a-col>
                        <a-col :span="5" class="left-top-wrap">
                             <h2 class="left-top">
                                <p class="top-text"><contacts-filled :style="{ color: '#3191ff' ,fontSize:'26px'}"/>今日订单数</p>
                                <span style="color:#3191ff" class="num">{{totalData.data.order_no_new_increase}}</span>
                                <div class="bottom-text">
                                    <span>总计订单数</span>
                                    <span>{{totalData.data.order_no}}</span>
                                </div>
                            </h2>
                        </a-col>
                        <a-col :span="4" class="left-top-wrap">
                             <h2 class="left-top">
                                <p class="top-text"><contacts-filled :style="{ color: '#3191ff' ,fontSize:'26px'}"/>今日收款金额</p>
                                <span style="color:#3191ff" class="num">{{totalData.data.order_amount_new_increase}}</span>
                                <div class="bottom-text">
                                    <span>总计收款</span>
                                    <span>{{totalData.data.order_amount}}</span>
                                </div>
                            </h2>
                        </a-col>
                    </a-row>

                    <h1 class="main-index-title" style="margin-top:26px">提问统计</h1>
                    
                    <a-row :gutter="32" class="left-bottom-conten" s>
                        <a-col :span="24" class="left-top-wrap left-bottom-wrap">
                            <div id="indexCharts" style="width:100%;height:380px;background:#fff;box-size:border-box;padding-top:20px"></div>
                        </a-col>

                        <a-col :span="24" class="left-top-wrap left-bottom-wrap">
                            <div id="indexCharts2" style="width:100%;height:380px;background:#fff;box-size:border-box;padding-top:20px"></div>
                        </a-col>
                    </a-row>

       
                       

                </a-col>
                <!-- <a-col class="left-top-wrap " :span="7">
                    <h2 class="left-top">
                        <p>今日提问次数</p>
                        <div>
                            <span style="color:#ff992e" class="num">{{totalData.data.chat_log_no_new_increase}}</span><snippets-filled :style="{ color: '#ff992e',fontSize:'34px' }"/>
                        </div>
                        
                    </h2>
                    <div class="right-top-wrap">
                       <user-outlined  :style="{ fontSize:'34px' }"/>
                       <p> 欢迎您，{{username}}</p>
                       <h2>今天是：{{currentDate}}</h2>
                    </div>

                    <h1 class="main-index-title" style="margin-top:26px">操作日志</h1>

                    <div style="width:100%;height:100%;background:#fff;box-size:border-box;padding:10px 12px 20px">
                         <a-list item-layout="horizontal" :data-source="logData.arr">
                            <template #renderItem="{ item }">
                            <a-list-item>
                                <a-list-item-meta :description="'操作人:'+ item.username+' 操作时间：'+item.create_time " >
                                <template #title>
                                    {{ item.title }}
                                </template>
                                <template #avatar>
                                    <smile-filled :style="{ color: '#5eb85e' }"/>
                                </template>
                                </a-list-item-meta>
                            </a-list-item>
                            </template>
                        </a-list>
                        <a-pagination
                        size="small"
                        :showSizeChanger="showSizeChanger"
                            :total="page.total"
                            @change="pageChange"
                            :show-total="total => `共 ${total} 条数据`"
                        />
                    </div>
                </a-col> -->
            </a-row>
        <!-- </a-card> -->
    </div>
</template>
<script>
import { ref ,reactive } from '@vue/reactivity'
import * as echarts from 'echarts';
import { getCurrentInstance } from 'vue'
import { message } from 'ant-design-vue';
import moment from "moment";
export default {
    setup(){
        let username=ref(sessionStorage.getItem("username"))

        let searchData=reactive({create_time:""})
        const { proxy } = getCurrentInstance();
        var currentDate=ref(moment(new Date()).format("YYYY-MM-DD"))

        // 搜索
        let dateFormat= ref(['YYYY-MM-DD','YYYY-MM-DD'])
        let dateChage=function(date,dateString){
            if(dateString[0]){
                searchData.create_time=moment(dateString[0]+' 00:00:00').unix()+"-"+moment(dateString[1]+' 23:59:59')/1000
            }else{
                 searchData.create_time=""
            }
            
            console.log(searchData.create_time)
        }
        let toSearch=function(){
            
            // console.log(searchData.create_time)
            getTotalData()
        }
        // 折线图
        let getTableData= function(){

            proxy.$http.post('/admin/indexlinechart',{},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    let myChart = echarts.init(document.getElementById('indexCharts'));
                    myChart.setOption({
                        legend: {
                            data: ['提问次数',"创作次数"]
                        },
                        xAxis: {
                            data: res.data.data.times
                        },
                        yAxis:{},
                        grid: {top:'17%',left:"6%",right:"6%"},
                        series: [
                            {
                                name: "提问次数",
                                type: "line",
                                smooth: true,
                                itemStyle:{
                                    normal: {
                                        lineStyle: {
                                            width:5,
                                            color:"#409EFC"
                                        }
                                    }
                                },
                                data:  res.data.data.data.chat_log.qa
                            },
                            {
                                name: "创作次数",
                                type: "line",
                                smooth: true,
                                itemStyle:{
                                    normal: {
                                        lineStyle: {
                                            width:5,
                                            color:"#3ea03f"
                                        }
                                    }
                                },
                                data:  res.data.data.data.chat_log.createt
                            }
                        ]
                    });


                    let myChart2 = echarts.init(document.getElementById('indexCharts2'));
                    myChart2.setOption({
                        legend: {
                            data: ['交易笔数',"交易金额"]
                        },
                        xAxis: {
                            data: res.data.data.times
                        },
                        yAxis:{},
                        grid: {top:'17%',left:"6%",right:"6%"},
                        series: [
                            {
                                name: "交易笔数",
                                type: "line",
                                smooth: true,
                                itemStyle:{
                                    normal: {
                                        lineStyle: {
                                            width:5,
                                            color:"#409EFC"
                                        }
                                    }
                                },
                                data: res.data.data.data.order.nos
                            },
                            {
                                name: "交易金额",
                                type: "line",
                                smooth: true,
                                itemStyle:{
                                    normal: {
                                        lineStyle: {
                                            width:5,
                                            color:"#3ea03f"
                                        }
                                    }
                                },
                                data: res.data.data.data.order.amounts
                            }
                        ]
                    });
                }else{
                   message.error(res.data.msg);
                }
            });
        }
        getTableData()

        // 数据
        var totalData=reactive({data:{}})
        var getTotalData=function(){
            proxy.$http.post('/admin/indexstatisticsall',{create_time:searchData.create_time},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    totalData.data=res.data.data
                }else{
                    message.error(res.data.msg);
                }
            });
        }
        getTotalData()
        // 操作日志
        let showSizeChanger=ref(false)
        let page=reactive({page:"1",pageSize:"6",total:""})
        let logData=reactive({arr:[]})
        let getLogdata=function(){
            proxy.$http.post('/admin/adminloglist',{page:page.page,pagesize:page.pageSize,search:searchData.value},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    logData.arr=res.data.data.data
                    page.total=res.data.data.total
                }else{
                    message.error(res.data.msg);
                }
            });
        }
        getLogdata()

        // 分页
        let pageChange=function(current){
            page.pageSize="6"
            page.page=current
            getLogdata()
        }
        return{
            username,currentDate,totalData,toSearch,searchData,dateFormat,dateChage,
            page,logData,showSizeChanger,pageChange
        }
    }
}
</script>