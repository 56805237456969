<template>
    <div>
            <div class="btn-wrap">
                <div class="btn-list">
                    <a-button type="primary" @click="openAdd1"><template #icon><plus-outlined /></template>添加微信支付</a-button>
                    <a-button type="danger" @click="delMore1"><template #icon><delete-outlined /></template>删除</a-button>
                </div>
                <div>
                    <a-input-group compact>
                        <a-input v-model:value="search1"  placeholder="请输入微信名称"/>
                        <a-button type="primary" @click="toSearch1">搜索</a-button>
                    </a-input-group>
                </div>
            </div>

            <a-table scroll={{ x: 1670 }}  row-key="id"  :pagination="false" :columns="columns" :data-source="dataList1.arr" 
            :row-selection="{ selectedRowKeys: selectedRowKeys1, onChange: onSelectChange1 }">
                <template #bodyCell="{ column,record}">

                    <template v-if="column.dataIndex === 'operation'">
                        <a-button type="link" @click="openEdit1(record.id)">编辑</a-button>
                        <a-button type="link" @click="del1(record.id)">删除</a-button>
                    </template>
                </template>
            </a-table>

            <a-pagination
                v-model:pageSize="page1.pageSize"
                show-size-changer
                :total="page1.total"
                @change="pageChange1"
                :show-total="total => `共 ${total} 条数据`"
            />


        <!-- 添加、编辑微信支付 -->
        <a-modal v-model:visible="visible" :title="title" @ok="handleOk" >
            <!-- <div style="width:390px"> -->
                <a-form
                    :model="formData.data"
                    :label-col="{ span: 4 }"
                    :wrapper-col="{ span: 20 }"
                    autocomplete="off"
                >
                    <a-form-item label="标题" name="title" >
                        <a-input placeholder="标题" v-model:value="formData.data.title" />
                    </a-form-item>

                    <a-form-item label="APPID" name="app_id" >
                        <a-input placeholder="绑定支付的APPID（必须配置，开户邮件中可查看）"  v-model:value="formData.data.app_id" />
                    </a-form-item>

                    <a-form-item label="SERCET" name="app_secret" >
                        <a-input placeholder="公众帐号secert（仅JSAPI支付的时候需要配置）" v-model:value="formData.data.app_secret" />
                    </a-form-item>
                    <a-form-item label="商户号" name="merchant_id" >
                        <a-input placeholder="商户号（必须配置，开户邮件中可查看）" v-model:value="formData.data.merchant_id" />
                    </a-form-item>
                    <a-form-item label="支付密钥" name="pay_key" >
                        <a-input placeholder="商户支付密钥" v-model:value="formData.data.pay_key" />
                    </a-form-item>
                    <a-form-item label="证书" name="apiclient_cert" >
                        <a-upload
                            v-model:file-list="fileList"
                            name="file"
                            :headers="headers"
                            action="https://chatapi.13524.net/admin/addlogfile"
                            @change="({ file }) => handleChangeImg(file)"
                        >
                         <a-button>
                            <UploadOutlined />
                            点击上传
                        </a-button>
                            
                        </a-upload>
                    </a-form-item>

                    <a-form-item label="证书密钥" name="apiclient_key" >
                        <a-upload
                            v-model:file-list="fileList"
                            name="file"
                            :headers="headers"
                            action="https://chatapi.13524.net/admin/addlogfile"
                            @change="({ file }) => handleChangeKey(file)"
                        >
                         <a-button>
                            <UploadOutlined />
                            点击上传
                        </a-button>
                            
                        </a-upload>
                    </a-form-item>
                </a-form>
            <!-- </div> -->
             
        </a-modal>
    </div>
</template>


<style>
/* .ant-table table{width: auto !important;} */
</style>

<script>
import { reactive,ref } from 'vue';
import {ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { getCurrentInstance } from 'vue'
import { message} from 'ant-design-vue';
const columns = [
    {title: 'ID',dataIndex: 'id',width:90},
    {title: '标题',dataIndex: 'title',width:130},
    {title: 'APPID',dataIndex: 'app_id',width:180},
    {title: 'SECERT',dataIndex: 'app_secret',width:280},
    {title: '商户号',dataIndex: 'merchant_id',width:150},
    {title: '支付密钥',dataIndex: 'pay_key',width:240},
    {title: '证书',dataIndex: 'apiclient_cert',width:120},
    {title: '证书密钥',dataIndex: 'apiclient_key',width:120},
    {title: '添加时间',dataIndex: 'create_time',width:180},
    {title: '操作',dataIndex: 'operation',width:180,fixed: 'right',}
]
export default {
    setup(){
        const { proxy } = getCurrentInstance()
        let dataList1=reactive({arr:[]})
        
        let page1=reactive({page:"",pageSize:"10",total:"20"})//分页


        // 搜索
        let search1=ref()
        let toSearch1=function(){
            page1.page="1"
            getTabledata1()
        }

        // 上传文件
        let loading=ref(false)
        let headers=reactive({'token':sessionStorage.getItem("token")})
        let handleChangeImg=function(info){
          
            if (info.status === 'uploading') {
                loading.value = true;
                return;
            }else if(info.status === 'done'){
                loading.value = false;
                console.log(info)
                formData.data.apiclient_cert=info.response.data.file_src
            }
        }

         let handleChangeKey=function(info){
          
            if (info.status === 'uploading') {
                loading.value = true;
                return;
            }else if(info.status === 'done'){
                loading.value = false;
                console.log(info)
                formData.data.apiclient_key=info.response.data.file_src
            }
        }

         // 添加、编辑
        let formData=reactive({data:{title:"",app_id:"",app_secret:"",merchant_id:"",pay_key:"",apiclient_cert:"",apiclient_key:""}})
        let title=ref("")
        let visible=ref(false)
        let openAdd1=function(){
            formData.data.title=""
            formData.data.app_id=""
            formData.data.app_secret=""
            formData.data.merchant_id=""
            formData.data.pay_key=""
            formData.data.apiclient_cert=""
            formData.data.apiclient_key=""
            title.value="添加微信支付"
            visible.value=true
        }

        let openEdit1=function(id){
            proxy.$http.post('/admin/paytypewechatpage',{id:id},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    visible.value=true
                    title.value="编辑微信支付"
                    formData.data=res.data.data.data
                }else{
                    message.error(res.data.msg);
                }
            });
        }

        let handleOk=function(){

            if(title.value=="添加微信支付"){
               
                proxy.$http.post('/admin/addpaytypewechat',{title:formData.data.title,app_id:formData.data.app_id,app_secret:formData.data.app_secret,
                merchant_id:formData.data.merchant_id,pay_key:formData.data.pay_key,apiclient_cert:formData.data.apiclient_cert,apiclient_key:formData.data.apiclient_key
                },{
                    headers:{
                        'token':sessionStorage.getItem("token")
                    }
                }).then((res) => {
                    if(res.data.status==200){
                        visible.value=false
                        message.success(res.data.msg)
                        getTabledata()
                    }else{
                        message.error(res.data.msg);
                    }
                });
                
                
            }else if(title.value=="编辑微信支付"){
                proxy.$http.post('/admin/updatepaytypewechat',{id:formData.data.id,title:formData.data.title,app_id:formData.data.app_id,app_secret:formData.data.app_secret,
                merchant_id:formData.data.merchant_id,pay_key:formData.data.pay_key,apiclient_cert:formData.data.apiclient_cert,apiclient_key:formData.data.apiclient_key
                },{
                    headers:{
                        'token':sessionStorage.getItem("token")
                    }
                }).then((res) => {
                    if(res.data.status==200){
                        visible.value=false
                        message.success(res.data.msg)
                        getTabledata()
                    }else{
                        message.error(res.data.msg);
                    }
                });
            }
        }


        // 列表
        // 微信
        let getTabledata1=function(){
            proxy.$http.post('/admin/paytypewechatlist',{page:page1.page,pagesize:page1.pageSize,title:search1.value},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    dataList1.arr=res.data.data.data
                    page1.total=res.data.data.total
                }else{
                    message.error(res.data.msg);
                }
            });
        }
        getTabledata1()
  

        // 多选
        let selectedRowKeys1=ref()
        let onSelectChange1=function(selected) {
            selectedRowKeys1.value = selected;
        }

         // 删除
        let delMore1=function(){
            var id=selectedRowKeys1.value.join(",")
            del1(id)
        }
        let del1=function(id){
            Modal.confirm({
                title: '你确定要删除吗？',
                icon: createVNode(ExclamationCircleOutlined),
            onOk() {
                proxy.$http.post('/admin/delpaytypewechat',{ids:id},{
                    headers:{
                        'token':sessionStorage.getItem("token")
                    }
                }).then((res) => {
                    if(res.data.status==200){
                        message.success(res.data.msg)
                        getTabledata1()
                    }else{
                        message.error(res.data.msg);
                    }
                });
            },
            onCancel() {
             console.log('Cancel');
            }
            })
            
        }

      

         // 分页
        let pageChange1=function(current, size){
            page1.pageSize=size
            page1.page=current
            getTabledata1()
        }

        return{
            headers,handleChangeImg,loading,handleChangeKey,
            openAdd1,openEdit1,formData,handleOk,formData,title,visible,
            selectedRowKeys1,onSelectChange1,delMore1,del1,
            toSearch1,search1,
            dataList1,columns,
            page1,pageChange1
        }
    }
}
</script>