<template>
    <div style="height:100%">
        <div class="login-content">
            <div class="login-wrap">
                <div class="left-img">
                    <img src="../assets/login-img.jpg" alt="" srcset="">
                </div>
                <div class="login-form">
                    <div class="login-logo">AI问天</div>
                    <p class="sub-title">
                        <span></span>
                        <label>快捷管理AI问天</label>
                        <span></span>
                    </p>

                    <div class="login-input">
                        <div class="input-wrap">
                            <a-input :size="size" autocomplete="off" v-model:value="formData.username" placeholder="请输入您的账号" />
                        </div>
                        <div  class="input-wrap">
                            <a-input-password :size="size" autocomplete="off" v-model:value="formData.password" placeholder="请输入您的密码" />
                        </div>
                        <div class=" verfig-wrap ">
                            <div class="input-wrap">
                                <a-input v-model:value="state.verify" placeholder="请输入验证码" />
                            </div>
                            <vueImgVerify ref="verifyRef" />

                        </div>
                    </div>

                    <a-button @click="$noMulClicks(toLogin)" :size="size" type="primary" block>登录</a-button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { ref,reactive } from '@vue/reactivity'
import vueImgVerify from "../components/verify.vue"; 
import { message } from 'ant-design-vue';
import {useRouter} from "vue-router"
import { getCurrentInstance } from 'vue'
export default {
    data(){
        return{
            size:"large",
            noClick: true
        }
    },
    components: {
        vueImgVerify,
    },
    setup(){
        let name=ref()
        let password=ref()

        const router=useRouter()
        const { proxy } = getCurrentInstance()

        const verifyRef = ref(null)
        const state = reactive({
            verify: "",
        })

        let formData=reactive({
            username:"",
            password:""
        })

        const toLogin = function() {
            // console.log(verifyRef.value.imgCode);
            // console.log(state.verify.toUpperCase());
            if (verifyRef.value.imgCode == state.verify.toUpperCase()) {
                proxy.$http.post('/admin/login',{username:formData.username,password:formData.password},{

                }).then((res) => {
                    if(res.data.status==200){
                        message.success(res.data.msg);
                        sessionStorage.setItem("token",res.data.data.token)
                        sessionStorage.setItem("roleid",res.data.data.admin_role_id)
                        sessionStorage.setItem("username",res.data.data.username)
                        sessionStorage.setItem("userid",res.data.data.id)
                        router.push({path:"/"})
                    }else{
                        message.error(res.data.msg);
                    }
                });
            } else {
                message.error('验证码错误');
            }
        };
        return{
            name,password,toLogin,state,formData,toLogin,verifyRef
        }
    }
}
</script>