<template>
  <a-layout style="height:100%">
    <a-layout-sider v-model:collapsed="collapsed" :trigger="null" collapsible>
      <div class="logo">
        <img :src="logosrc" alt="">
      </div>
      <a-menu v-model:selectedKeys="selectedKeys" theme="dark" mode="inline">
        <a-menu-item :key="0" @click="toRouter('首页','/index')">
            <home-filled />
            <span>首页</span>
            </a-menu-item>
          <template v-for="item in menulist.arr">
            <a-sub-menu :key="item.id" v-if="item.children">
              
              <template #title>
                <span>
                  <component :is="item.icon" />
                  <span>{{item.title}}</span>
                </span>
              </template>
              <a-menu-item @click="toRouter(option.title,option.href)" v-for="option in item.children" :key="option.id">{{option.title}}</a-menu-item>
            </a-sub-menu>

            <a-menu-item :key="item.id" v-else @click="toRouter(item.title,item.href)">
              <component :is="item.icon" />
              <span>{{item.title}}</span>
            </a-menu-item>
          </template>
      </a-menu>
    </a-layout-sider>
    <a-layout>
      <a-layout-header style="background: #fff; padding: 0 24px 0 0;display:flex;justify-content: space-between;">
        <menu-unfold-outlined
          v-if="collapsed"
          class="trigger"
          @click="() => (collapsed = !collapsed)"
        />
        <menu-fold-outlined v-else class="trigger" @click="() => (collapsed = !collapsed)" />

        <div>
          <a-dropdown :trigger="['click']" placement="bottomRight">
            <span class="ant-dropdown-link" style="cursor:pointer">
              <user-outlined />
              {{username}}
            </span>
            <template #overlay>
              <a-menu>
                <a-menu-item key="0" @click="toPerson"><contacts-outlined /> 账户信息</a-menu-item>
                <a-menu-divider />
                <a-menu-item key="3" @click="toLoginout"  ><login-outlined /> 退出登录</a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </div>
      </a-layout-header>
      <a-page-header style="padding-bottom:0" :breadcrumb="{ routes }" />
      <a-layout-content
        :style="{ margin: '12px 16px 24px', padding: '24px', background: '#fff', minHeight: '280px' ,overflowY:'auto'}"
      >
        <router-view/>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>
<script >
import {reactive, ref } from 'vue';
import {useRouter} from "vue-router"
import { getCurrentInstance } from 'vue'
import { message } from 'ant-design-vue';
export default ({
  setup() {
    const router=useRouter()
    let routes =ref([{path:"/index",breadcrumbName:"首页"}])
    const { proxy } = getCurrentInstance()
    let selectedKeys=ref([])
    let collapsed=ref(false)
    let menulist=reactive({arr:[
      // {id:1,title:"路径管理",icon:"codepen-square-filled",href:"/",children:[
      //   { title:"路径列表",href:"/urllist",id:2 },
      //   { title:"公用屏蔽库",href:"/blacklist",id:9 }
      // ]},

      // {id:12,title:"管理员日志",icon:"codepen-square-filled",href:"/",children:[
      //   { title:"日志列表",href:"/loglist",id:13 },
      // ]},

      // {id:3,title:"权限管理",icon:"security-scan-filled",href:"/",children:[
      //   { title:"角色列表",href:"/rolelist" ,id:4},
      //   { title:"节点列表",href:"/nodelist",id:5 }
      // ]},

      // {id:7,title:"账户管理",icon:"security-scan-filled",href:"/",children:[
      //   { title:"账户列表",href:"/member" ,id:6},
      //   { title:"账户信息",href:"/person",id:8 }
      // ]},

      // {id:11,title:"系统设置",icon:"security-scan-filled",href:"/",children:[
      //   { title:"基础设置",href:"/system" ,id:10}
      // ]}
    ]})

    let username=ref(sessionStorage.getItem("username"))

     // 获取菜单
    let getMenu=function(){
      let roleid= sessionStorage.getItem("roleid")
      if(roleid==0){
        roleid=""
      }
      proxy.$http.post('/admin/nodelist',{admin_role_id:roleid,menu_status:1},{
          headers:{
            'token':sessionStorage.getItem("token")
          }
        }).then((res) => {
            if(res.data.status==200){
               menulist.arr=res.data.data
            }else{
              message.error(res.data.msg);
            }
        });
    }
    getMenu()

     // logo
    let logosrc=ref()
    let getSystem=function(){
      proxy.$http.post('/admin/systemsetpage',{id:1},{
          headers:{
              'token':sessionStorage.getItem("token")
          }
      }).then((res) => {
          if(res.data.status==200){
            logosrc.value='https://chatapi.13524.net/'+res.data.data.data.log_url
          }else{
            message.error(res.data.msg);
          }
      });
    }
    getSystem()

    let toLoginout=function(){
      sessionStorage.setItem("token","")
      router.push({path:"/login"})
    }

    let toPerson=function(){
      router.push({path:"/person"})
    }

    // 路由跳转
    let toRouter=function(title,href){
      routes.value=[{path:"/index",breadcrumbName:"首页"}]
      routes.value.push({path:href,breadcrumbName:title})
      router.push({path:href})
    }
    return {
      logosrc,
      routes,toLoginout,toPerson,
      menulist,toRouter,username,
      selectedKeys,collapsed
    };
  },
});
</script>
<style>
.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.logo {
  height: 32px;
  /* background: rgba(255, 255, 255, 0.3); */
  margin: 16px;
  overflow: hidden;
}

.site-layout .site-layout-background {
  background: #fff;
}
</style>