<template>
    <div>
        <a-tabs  animated :size="size">
            <a-tab-pane key="1" tab="网站信息" class="person-wrap">
                <!-- <h2 class="person-title">基本信息</h2> -->
                <a-form
                    :model="system.data"
                    name="basic"
                    :label-col="{ span: 3 }"
                    :wrapper-col="{ span: 8 }"
                    autocomplete="off"
                    style="width:80%"
                >
                    <a-form-item label="网站名称" >
                        <a-input v-model:value="system.data.title" placeholder="网站名称"/>
                    </a-form-item>

                    <a-form-item label="Logo" >
                        <a-upload
                            v-model:file-list="fileList"
                            name="file"
                            list-type="picture-card"
                            class="avatar-uploader logoUpload"
                            :headers="headers"
                            :show-upload-list="false"
                            action="https://chatapi.13524.net/admin/addlogfile"
                            @change="({ file }) => handleChangeImg(file)"
                        >
                            <img v-if="system.data.log_url" :src="'https://chatapi.13524.net/'+system.data.log_url" style="width:168px;height:32px" alt="avatar" />
                            <div v-else>
                                <loading-outlined v-if="loading"></loading-outlined>
                                <plus-outlined v-else></plus-outlined>
                                <div class="ant-upload-text">图片上传</div>
                            </div>
                            
                        </a-upload>
                        <span>（图片大小为168*32）</span>
                    </a-form-item>
                   <a-form-item label="免费数量" >
                        <a-input-group compact>
                            <a-input  type="number" placeholder="免费数量" v-model:value="system.data.free_times" style="width: 50%" />
                            <a-select  v-model:value="system.data.free_times_unit">
                                <a-select-option value="d">日</a-select-option>
                                <a-select-option value="w">周</a-select-option>
                                <a-select-option value="m">月</a-select-option>
                                <a-select-option value="y">年</a-select-option>
                                <a-select-option value="o">不限</a-select-option>
                            </a-select>
                        </a-input-group>
                    </a-form-item>
                    <a-form-item :wrapper-col="{ offset: 3, span: 16 }">
                        <a-button @click="toSubmit" type="primary" >修改配置</a-button>
                    </a-form-item>
                </a-form>
            </a-tab-pane>
        </a-tabs>
    </div>
</template>

<script>
import { getCurrentInstance , reactive,ref} from 'vue'
import { message} from 'ant-design-vue';
export default {
    data(){
        return{
            size:"large"
        }
    },
    setup(){
        const { proxy } = getCurrentInstance()
        let system=reactive({data:{title:"",log_url:"",free_times:"",free_times_unit:"d"}})

         // 详情
        let getSystem=function(){
            proxy.$http.post('/admin/systemsetpage',{id:1},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    system.data=res.data.data.data
                }else{
                    message.error(res.data.msg);
                }
            });
        }
        getSystem()



        // 上传logo
        let loading=ref(false)
        let headers=reactive({'token':sessionStorage.getItem("token")})
         let handleChangeImg=function(info){
          
            if (info.status === 'uploading') {
                loading.value = true;
                return;
            }else if(info.status === 'done'){
                loading.value = false;
                console.log(info)
                system.data.log_url=info.response.data.file_src
            }
        }

        // 修改配置
        let toSubmit=function(){
            proxy.$http.post('/admin/updatesystemset',{id:1,title:system.data.title
            ,log_url:system.data.log_url,free_times:system.data.free_times,free_times_unit:system.data.free_times_unit},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    message.success(res.data.msg);
                }else{
                    message.error(res.data.msg);
                }
            });
        }


        return{
            system,toSubmit,
            handleChangeImg,loading,headers
        }
    }
}
</script>