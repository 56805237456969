<template>
    <div>
        <!-- <a-card title="知识讲堂" :bordered='false'> -->
            <div class="btn-wrap">
                <div class="search-list" style="display:flex;align-items: center;">
                    <!-- <a-input-group compact style="margin-right:20px">
                        <a-range-picker @change="changDate" :format="dateFormat"  v-model:value="time" />
                    </a-input-group> -->
                    <a-input style="margin-right:20px" v-model:value="username" placeholder="请输入用户名"/>
                    <a-input style="margin-right:20px" v-model:value="telephone" placeholder="请输入手机号码"/>

                    <a-button type="primary" @click="toSearch">搜索</a-button>

                    <p style="    white-space: nowrap;margin-left:20px">
                        <span>提问总次数:</span>
                        <span>{{totalData.data.chat_log_no}}</span>
                    </p>
                    <p style="    white-space: nowrap;margin-left:20px">
                        <span>今日提问次数:</span>
                        <span>{{totalData.data.chat_log_no_new_increase}}</span>
                    </p>
                    <!-- <a-button type="primary" @click="openAdd"><template #icon><plus-outlined /></template>添加患者</a-button>
                    <a-button type="danger" @click="delMore"><template #icon><delete-outlined /></template>删除</a-button> -->
                </div>
                <!-- <div>
                    <a-input-group compact>
                        <a-input v-model:value="search"  placeholder="请输入患者姓名"/>
                        <a-button type="primary" @click="toSearch">搜索</a-button>
                    </a-input-group>
                </div> -->
            </div>

            <a-table row-key="id" :pagination="false" :columns="columns" :data-source="dataList.arr"  >
                <template #bodyCell="{ column,record}">
                    <template v-if="column.dataIndex === 'create_time'">
                         {{ changeFormdate(record.create_time) }}
                    </template>
                    <!-- <template v-if="column.dataIndex === 'operation'">
                        <a-button type="link" @click="openEdit(record.id)">编辑</a-button>
                        <a-button type="link" @click="del(record.id)">删除</a-button>
                    </template> -->
                </template>
            </a-table>

            <a-pagination
                v-model:pageSize="page.pageSize"
                show-size-changer
                :total="page.total"
                @change="pageChange"
                :show-total="total => `共 ${total} 条数据`"
            />
        <!-- </a-card> -->

    </div>
</template>


<script>
import { reactive,ref,computed } from 'vue';

import { getCurrentInstance } from 'vue'
import { message} from 'ant-design-vue';
import moment from 'moment';
import { formatDate } from "../../common/filter.js";
const columns = [
    {title: 'ID',dataIndex: 'id',width:90},

    {title: '用户名',dataIndex: 'user_username',width:110},
    {title: '用户号码',dataIndex: 'user_telephone'},
    {title: '问题',dataIndex: 'question'},
    {title: '回答',dataIndex: 'answer',width:520},
    {title: '请求时间',dataIndex:'create_time',width:180}
];
export default {
    setup(){
        const changeFormdate = computed(() => {
            return (time) => {
                let date = new Date(time);
                return formatDate(date);
            };
        });
        const { proxy } = getCurrentInstance()
        let dataList=reactive({arr:[]})
        
        let page=reactive({page:"1",pageSize:"10",total:""})//分页

        var totalData=reactive({data:{}})
        var getTotalData=function(){
            proxy.$http.post('/admin/indexstatisticsall',{create_time:""},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    totalData.data=res.data.data
                }else{
                    message.error(res.data.msg);
                }
            });
        }
        getTotalData()

        // 搜索
        let search=ref()
        let username=ref()
        let toSearch=function(){
            page.page="1"
            getTabledata()
        }
        
        let telephone=ref()
        // let changDate=function(date,string){
        //     console.log(string)
        //     if(string[0]){
        //         create_time.value=moment(string[0]+' 00:00:00').unix()+"-"+moment(string[1] +' 23:59:59').unix()
        //     }else{
        //         create_time.value=""
        //     }
            
        // }
        // 列表
        let getTabledata=function(){
            proxy.$http.post('/admin/chatloglist',{page:page.page,pagesize:page.pageSize,username:username.value,telephone:telephone.value},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    dataList.arr=res.data.data.data
                    page.total=res.data.data.total
                }else{
                    message.error(res.data.msg);
                }
            });
        }
        getTabledata()

    

        // 分页
        let pageChange=function(current, size){
            page.pageSize=size
            page.page=current
            getTabledata()
        }
        return{
            changeFormdate,totalData,
            toSearch,search,username,telephone,
            columns,dataList,pageChange,page
        }
    }
}
</script>