<template>
    <div>
        <!-- <a-card title="个人中心" :bordered='false'>
            <a-row class="btn-wrap" :gutter="30">
                <a-col :span="11" :offset="1">
                    <a-avatar :size="80">
                        <a-icon :size="50"><user-outlined :style="{ fontSize: '30px'}" /></a-icon>
                    </a-avatar> -->

                    <a-tabs  :tab-position="right" animated :size="size">
                        <a-tab-pane key="1" tab="基本设置" class="person-wrap">
                            <h2 class="person-title">基本信息</h2>
                            <a-list item-layout="horizontal">
                                <a-list-item>
                                    <a-list-item-meta :description="userinfo.data.username" >
                                        <template #title>
                                            <span>用户名</span>
                                        </template>
                                    </a-list-item-meta>
                                </a-list-item>

                                <a-list-item>
                                    <a-list-item-meta :description="userinfo.data.title_admin_role" >
                                        <template #title>
                                            <span>角色</span>
                                        </template>
                                    </a-list-item-meta>
                                </a-list-item>

                                <a-list-item>
                                     <template #actions>
                                            <a key="list-loadmore-edit"  @click="openTel">修改号码</a>
                                        </template>
                                    <a-list-item-meta :description="userinfo.data.telephone" >
                                        <template #title>
                                            <span>手机号码</span>
                                        </template>
                                       
                                    </a-list-item-meta>
                                </a-list-item>
                            </a-list>
                        </a-tab-pane>
                        
                        <a-tab-pane key="2" tab="安全设置" class="person-wrap">
                            <h2 class="person-title">基本信息</h2>
                            <a-list item-layout="horizontal">

                                <a-list-item>
                                     <template #actions>
                                            <a key="list-loadmore-edit" @click="openPwd">修改密码</a>
                                        </template>
                                    <a-list-item-meta description="***********" >
                                        <template #title>
                                            <span>登录密码</span>
                                        </template>
                                       
                                    </a-list-item-meta>
                                </a-list-item>
                            </a-list>
                        </a-tab-pane>
                    </a-tabs>


        <!--编辑密码 -->
        <a-modal v-model:visible="visible" :title="title" @ok="handleOk" >
             <a-form
                :label-col="{ span: 5 }"
                :wrapper-col="{ span: 16 }"
                autocomplete="off"
            >
                <a-form-item v-if="ispwd==false" label="手机号码"  >
                    <a-input v-model:value="userinfo.data.telephone" placeholder="请输入手机号码" />
                </a-form-item>
                <a-form-item v-if="ispwd" label="新密码" >
                    <a-input-password v-model:value="formData.data.password" placeholder="请输入新密码" />
                </a-form-item>
                <a-form-item v-if="ispwd" label="确认密码" >
                   <a-input-password v-model:value="formData.data.repassword" placeholder="请确认新密码" />
                </a-form-item>
            </a-form>
        </a-modal>
    </div>
</template>

<script>
import { reactive,ref } from '@vue/reactivity'
import { getCurrentInstance } from 'vue'
 import { message} from 'ant-design-vue';
export default {
    data(){
        return{
            right:"left",
            size:"large"
        }
    },
    setup(){
        const { proxy } = getCurrentInstance()
        let userinfo=reactive({data:{}})

        var id=ref()
        let getUserifo=function(){
            proxy.$http.post('/admin/adminuserpage',{id:sessionStorage.getItem("userid")},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    userinfo.data=res.data.data.data
                    id.value=res.data.data.data.id
                }else{
                    message.error(res.data.msg);
                }
            });
        }
        getUserifo()

        // 编辑
        let title=ref()
        let ispwd=ref(true)
        let formData=reactive({data:{password:"",repassword:""}})
        let visible=ref(false)
        let openPwd=function(){
            visible.value=true
            ispwd.value=true
            title.value="修改密码"
        }
        let openTel=function(){
            visible.value=true
            ispwd.value=false
            title.value="修改号码"
        }
        let handleOk=function(){
            if(formData.data.password!=formData.data.repassword){
                message.error("两次密码不一致");
                return false
            }
            proxy.$http.post('/admin/updateadminuser',{id:id.value,password:formData.data.password,telephone:userinfo.data.telephone},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    visible.value=false
                    formData.data.repassword=""
                    formData.data.password=""
                    message.success(res.data.msg)
                }else{
                    message.error(res.data.msg);
                }
            });
        }

        return{
            userinfo,formData,title,ispwd,
            visible,openPwd,handleOk,openTel
        }
    }
}
</script>